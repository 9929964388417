import React from "react";
import { Root } from "./style";

import { useSiteData } from "utils";

import FAQs from "components/Common/FAQs";
import HoldingGemFighterNFTs from "components/Common/HoldingGemFighterNFTs";
import PageHeader from "components/Common/PageHeader";
import ReadyToStart from "components/Common/ReadyToStart";
import SiteSection from "components/Common/SiteSection";

const GemFighterP2E = () => {
  const {
    resources: {
      banners: { holdingGemFighterNftsLivePill },
    },
    pages: {
      gemFighterP2E: { faqs, faqsTitle, faqsIntro, youtubeID },
    },
  } = useSiteData();

  return (
    <Root>
      <PageHeader
        page="gemFighterP2E"
        rawSubtitle={holdingGemFighterNftsLivePill}
      />
      <div className="body-width">
        <div
          style={{
            margin: "80px 0 0",
            position: "relative",
            paddingBottom: "56.25%",
            height: 0,
            overflow: "hidden",
            borderRadius: "30px",
          }}
        >
          <iframe
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              left: "0px",
              top: "0px",
              overflow: "hidden",
            }}
            frameBorder="0"
            type="text/html"
            src={`https://www.youtube.com/embed/${youtubeID}?autoplay=1&mute=1`}
            width="100%"
            height="100%"
            allowFullScreen
          ></iframe>
        </div>
      </div>
      <SiteSection path="gemFighterP2E.contentSection" image="contentImage" />
      <HoldingGemFighterNFTs />
      <div className="faqs">
        <h2>{faqsTitle}</h2>
        <p>{faqsIntro}</p>
      </div>
      <FAQs faqs={faqs} />
      <ReadyToStart />
    </Root>
  );
};

export default GemFighterP2E;
